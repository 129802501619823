@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import './reset.scss';

body {
	margin: 0;
	background: #f2f2f2;
	font-family: 'Roboto', -apple-system, BlinkMacSystemFont, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	.saran-radios {
		padding: 30px;
		width: 1280px;
		height: 720px;
		background: rgba(0, 0, 0, 0.05);

		display: flex;
		flex-direction: column;

		.slick-slide {
			padding: 0 20px;
		}

		.slick-arrow {
			position: absolute;
			bottom: -124px;
			top: inherit;
			left: 50%;
			width: 48px;
			height: 48px;
			border-radius: 48px;
			background: #f6f6f6;
			display: flex !important;
			align-items: center;
			justify-content: center;
			transition: all 0.3s;
			z-index: 1100;
			transform: translate(50%, 0);

			&::before {
				content: '';
				display: block;
				width: 12px;
				height: 12px;

				border: 2px solid black;
				border-top-width: 0;
				border-left-width: 0;
				transition: all 0.3s;
			}

			&.slick-next {
				&::before {
					transform: rotate(-45deg);
				}
			}

			&.slick-prev {
				margin-left: -90px;

				&::before {
					transform: rotate(135deg);
				}
			}

			&:not(.slick-disabled):hover {
				background: #303030;

				&::before {
					border-color: #fff;
				}
			}

			&.slick-disabled {
				cursor: not-allowed;
			}
		}
	}

	section {
		margin-top: auto;
		margin-bottom: auto;
		position: relative;
	}

	.radio-card {
		background: rgba(0, 0, 0, 0.05);
		padding: 30px;
		border-radius: 20px;
		width: 240px;
		height: 266px;
		display: flex !important;
		flex-direction: column;
		align-items: center;

		.radio-logo {
			height: 36px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.radio-motto {
			font-size: 14px;
			font-weight: 400;
			color: rgba(black, 0.4);
			margin-top: 30px;
			text-align: center;
			line-height: 1.5;
		}

		button {
			font-size: 16px;
			font-weight: 500;
			background: #303030;
			padding: 25px 50px;
			border-radius: 20px;
			color: #fff;
			text-transform: uppercase;
			transition: background 0.3s;
			margin-top: auto;

			&:hover,
			&:active {
				background: rgba(#303030, 0.5);
			}

			&.active {
				background: tomato;
			}
		}
	}

	video,
	.rhap_container {
		display: none;
	}
}
